#footer {
  background: #776b63;
  position: relative;
  color: #fff;
  
  &:before {
    display: block;
    content: "";
    font-size: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 16px;
    background: url("../images/footer-shadow.png") repeat-x left top;
    z-index: 5;
  }
  
  a {
    color: inherit;
  }
  
  .footer-wrapper {
    margin-right: 200px;
    padding: 30px 45px 25px 0;
    z-index: 2;
    position: relative;
    
    &:before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      font-size: 0;
      border-left: solid 1px #574f4a;
      border-right: solid 1px #9a918c;
      z-index: 1;
    }
    
    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      background: #6c6059;
      width: 5000px;
      z-index: 0;
    }
    
    .footer-logo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 300px;
      z-index: 5;
      
      a {
        display: block;
        position: absolute;
        top: 50%;
        width: 150px;
        left: 25px;
        margin-top: -55px;
      }
    }
    
    .footer-column {
      line-height: 1.7142;
      position: relative;
      padding: 0 0 20px 55px;
      
      .icon {
        position: absolute;
        top: 0;
        left: 0;
        color: $brand-warning;
        font-size: 40px;
        line-height: 1;
      }
    }
    
    .social-links {
      font-size: 24px;
      text-align: right;
      padding: 0 0 20px;
      
      a {
        display: inline-block;
        color: #574f4a;
        transition: all .3s ease-in-out;
        
        .fa-stack-1x {
          color: #776b63;
        }
        
        &:hover {
          color: $brand-warning;
        }
      }
    }
    
    .copyright-text {
      padding: 15px 0 0;
      position: relative;
      font-size: 12px;
      border-top: solid 1px #574f4a;
      
      &:before {
        display: block;
        content: "";
        font-size: 0;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-top: solid 1px #a19993;
      }
    }
  }
  
  @media (min-width: $screen-lg) {
    .footer-wrapper {
      padding-right: 50px;
      
      .footer-logo {
        a {
          width: 182px;
          margin-top: -67px;
          left: 50px;
        }
      }
    }
  }
  
  @media (max-width: $screen-md-max) {
    .footer-wrapper {
      .social-links {
        font-size: 20px;
      }
    }
  }
  
  @media (max-width: $screen-sm-max) {
    .footer-wrapper {
      margin: 0;
      padding: 20px 0 0;
      
      &:before,
      &:after {
        display: none;
      }
      
      .footer-logo {
        position: relative;
        left: auto;
        bottom: auto;
        top: auto;
        background: #6c6059;
        width: auto;
        padding: 15px;
        margin: 15px -15px 0;
        text-align: center;
        
        a {
          margin: auto;
          width: 182px;
          max-width: 90%;
          position: static;
        }
      }
      
      .social-links {
        padding: 15px 0;
        text-align: center;
        position: relative;
        
        &:before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          font-size: 0;
          border-top: solid 1px #574f4a;
          border-bottom: solid 1px #9a918c;
          z-index: 1;
        }
      }
    }
  }
}

#gallery-row {
  background: #ebebea;
  padding: 30px 0;
  
  .content-column & {
    padding: 10px 20px;
  }
}