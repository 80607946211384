@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic);

h1, .h1 {
  margin-bottom: 15px;
  
  & + h2,
  & + .h2 {
    margin-top: 15px;
  }
}

h2, .h2 {
  margin-bottom: 20px;
}

#{$headings} {
  &:first-child {
    margin-top: 0;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
}

p, ul, ol {
  &:last-child {
    margin-bottom: 0;
  }
}

.display-table {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  
  .display-table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.gallery {
  .row {
    margin: 0 -10px;
    
    .gallery-item {
      float: left;
      width: 25%;
      padding: 10px;
      
      a {
        display: block;
      }
      
      &:nth-child(4n+1) {
        clear: left;
      }
    }
  }
  
  @media (max-width: $screen-sm-max) {
    .row {
      .gallery-item {
        width: 33.333%;
        
        &:nth-child(4n+1) {
          clear: none;
        }
        
        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
  
  @media (max-width: $screen-xs) {
    .row {
      .gallery-item {
        width: 50%;
        
        &:nth-child(3n+1) {
          clear: none;
        }
        
        &:nth-child(2n+1),
        &:nth-child(4n+1) {
          clear: left;
        }
      }
    }
  }
}

.white-popup-block {
  background: #FFF;
  padding: 10px;
  text-align: left;
  max-width: 540px;
  margin: 40px auto;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  
  .popup-content {
    padding: 80px 0 20px;
    background: no-repeat center top;
    background-size: cover;
    
    img {
      &:first-child {
        margin-top: 0;
      }
    }
  }
  
  .popup-text {
    padding: 0 80px;
    background: url("../images/popup-text-overlay.png") repeat-y center top;
    color: #000;
    font-size: 14px;
    line-height: 21px;
    padding: 20px 0;
  }
  
  .blue-button {
    margin: 20px auto 0;
  }
  
  .mfp-close {
    position: absolute;
    width: 43px;
    height: 43px;
    background: url("../images/popup-close.png") no-repeat left top;
    font-size: 0;
    top: -10px;
    right: -10px;
    opacity: 1;
  }
}