// Grid system
.content-column {
  width: 72.223%;
  float: left;
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
}

.sidebar {
  width: 27.777%;
  float: left;
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
}

@media (max-width: $screen-md-max) {
  .content-column {
    width: 65%;
  }
  
  .sidebar {
    width: 35%;
  }
}

@media (max-width: $screen-sm-max) {
  .content-column {
    width: 60%;
  }
  
  .sidebar {
    width: 40%;
  }
}

@media (max-width: $screen-xs-max) {
  .content-column,
  .sidebar {
    width: 100%;
    float: none;
  }
}

.container {
  position: relative;
  z-index: 2;
}