.grey-button,
.blue-button {
  clear: both;
  display: block;
  line-height: 30px;
  padding: 2px 30px 3px 20px;
  background: url("../images/button-bg.png") no-repeat right top / 100% 300%;
  font-family: $font-family-serif;
  font-size: 16px;
  font-weight: normal;
  border: none;
  outline: none;
  color: #636363;
  max-width: 300px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  
  &.blue-button {
    background-position: right bottom;
    color: #fff !important;
    max-width: 400px;
    padding-left: 15px;
  }
  
  &.active,
  .active > &,
  &:hover,
  &:focus {
   background-position: right -35px;
   color: $brand-primary !important;
   text-decoration: none;
  }
  
  .sidebar & {
    margin-right: -25px;
  }
}