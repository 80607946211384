#page {
  overflow: hidden;
}

.home {
  #content-home {
    background: #d6d6d6 url("../images/home-content-bg.png") no-repeat center / auto 100%;
    padding: 100px 0 120px;
    position: relative;
    
    .text {
      max-width: 940px;
      margin: auto;
      @include clearfix;
      
      h1 + h2 {
        margin-bottom: 30px;
      }
    }
    
    &:after {
      background: url("../images/nav-shadow.png") repeat-x left bottom;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 16px;
      z-index: 10;
      display: block;
      content: "";
      font-size: 0;
    }
  }
  
  #home-bottom {
    position: relative;
    padding: 40px 0;
    background: #fef8f1;
    
    &:before {
      position: absolute;
      z-index: 1;
      display: block;
      content: "";
      font-size: 0;
      top: 2px;
      left: 50%;
      height: 1px;
      margin-left: -5000px;
      border-left: solid 5000px #b7b7b7;
      border-right: solid 5000px #d7d7d7;
    }
    
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 50%;
      display: block;
      content: "";
      font-size: 0;
      z-index: 0;
      background: #fff;
    }
    
    img {
      margin-top: 0;
    }
    
    .home-news {
      margin-left: 30px;
      
      .latest-news {
        margin: 20px 0 15px;
        border-top: solid 1px #cdcfe3;
        
        .news-item {
          border-bottom: solid 1px #cdcfe3;
          padding: 10px 0;
          line-height: 1.5;
          @include clearfix;
          
          date {
            float: right;
            font-size: 12px;
            font-style: italic;
            color: #574f4a;
            white-space: nowrap;
            display: inline-block;
            margin-right: 10px;
          }
          
          h4 {
            margin: 0 0 5px;
            font-family: $font-family-sans-serif;
            font-weight: bold;
            
            a {
              
            }
          }
          
          .news-intro {
            clear: left;
            
            a {
              text-decoration: underline;
              
              &:hover {
                text-decoration: none;
              }
              
              &.read-more {
                display: inline-block;
              }
            }
          }
        }
      }
      
      .see-more-news {
        text-align: right;
        font-family: $font-family-serif;
        font-size: 16px;
      }
    }
  }
  
  @media (max-width: $screen-xs-max) {
    #content-home {
      padding: 50px 0 60px;
    }
    
    #home-bottom {
      padding: 25px 0 0;
      
      &:before {
        border-right-color: #b7b7b7;
      }
      
      &:after {
        display: none;
      }
      
      .home-news {
        background: #fff;
        margin: 25px -15px 0;
        padding: 25px 15px;
      }
    }
  }
}

#page-content {
  padding: 45px 0;
  
  .content-wrapper {
    margin: 0 0 30px;
  }
}

.page-contact {
  .content-column {
    iframe {
      width: 100%;
      background: #fff;
      vertical-align: top;
      box-shadow: 2px 2px 4px rgba(0,0,0,.45);
      border: solid 1px #fff;
    }
  }
  
  .contact-columns {
    @include clearfix;
    margin: 30px -15px 0;
    
    .column-left {
      float: left;
      padding: 0 15px;
      width: 60%;
    }
    
    .column-right {
      float: left;
      padding: 0 15px;
      width: 40%;
    }
    
    @media (max-width: $screen-md-max) {
      .column-left,
      .column-right {
        float: none;
        width: auto;
      }
      
      .column-right {
        margin-top: 30px;
      }
    }
  }
}