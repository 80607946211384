.articles-list {
  padding: 20px 0 0;
  
  article {
    padding: 30px 0;
    border-top: solid 1px #d7d7d7;
    
    h3 {
      font-size: 22px;
    }
  }
}

.pagination {
  border-top: solid 1px #d7d7d7;
  border-bottom: solid 1px #d7d7d7;
  position: relative;
  padding: 13px 0;
  font-size: 0;
  display: block;
  text-align: center;
  float: none;
  
  &:empty {
    display: none;
  }
  
  > * {
    display: inline-block;
    margin: 3px 5px;
    font-size: 20px;
    color: #636363;
    text-transform: uppercase;
    text-align: center;
    min-width: 38px;
    line-height: 24px;
    padding: 5px 10px;
    background-color: #e1e1e1;
    box-shadow: 2px 2px 4px rgba(0,0,0,.28);
    border: solid 1px #fff;
    transition: all .3s ease-in-out;
    
    &:hover,
    &.current {
      text-decoration: none;
      background: $brand-warning;
      color: #fff;
    }
  }
}