// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:                 #374091;
$brand-warning:                 #f2b978;
$text-color:                    $brand-primary;

$font-family-sans-serif:        'Open Sans', sans-serif;
$font-family-serif:             'Calisto MT', Georgia, serif;
$font-size-base:                14px;
$line-height-base:              2.2857;

$headings:                      "h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6";;
$headings-font-family:          $font-family-serif;
$headings-font-weight:          normal;
$font-size-h1:                  36px;
$font-size-h2:                  22px;
$font-size-h3:                  18px;
$font-size-h4:                  14px;
$font-size-h5:                  12px;
$font-size-h6:                  11px;