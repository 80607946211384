.sidebar {
  line-height: 1.857;
  
  .widget {
    position: relative;
    padding-right: 25px;
    
    & + .widget {
      margin-top: 25px;
      padding-top: 25px;
      
      &:before {
        display: block;
        content: "";
        font-size: 0;
        height: 4px;
        position: absolute;
        top: 0;
        left: 0;
        right: 25px;
        border-top: solid 1px #9a9a9a;
        border-bottom: solid 1px #9a9a9a;
      }
    }
    
    .menu {
      list-style: none;
      margin: 0;
      padding: 0;
      
      > li {
        a {
          @extend .grey-button;
        }
        
        &.active {
          a {
            background-position: right -35px;
            color: $brand-primary !important;
          }
        }
        
        .single &.active,
        .category-news &.active.menu-all-posts,
        .category-events &.active.menu-all-posts {
          a {
            color: #636363 !important;
            background-position: right top;
            
            &:hover {
              color: $brand-primary !important;
              background-position: right -35px;
            }
          }
        }
        
        & + li {
          margin-top: 3px;
        }
      }
    }
  }
  
  p {
    .grey-button {
      margin-top: 10px;
    }
  }
}