#banner {
  overflow: hidden;
  
  .home & {
    /*height: 548px;*/
  }
  
  .fixed-nav & {
    margin: 0 0 77px;
  }
}

#banner-image {
  height: 223px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

#slideshow {
  height: 100%;

  .slide {
    height: 548px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    
    .slide-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      color: $brand-warning;
      
      .slide-quote {
        max-width: 480px;
        font-family: $font-family-serif;
        font-size: 31px;
        line-height: 1.161;
        position: relative;
        
        &:before,
        &:after {
          display: block;
          position: absolute;
          top: -10px;
          font-size: 75px;
          line-height: 1;
          opacity: .5;
        }
        
        &:before {
          content: '\201C';
          left: -.6em;
        }
        
        &:after {
          content: '\201D';
          right: -.6em;
        }
      }
      
      .slide-author {
        max-width: 480px;
        margin: 20px 0 0;
        text-align: right;
      }
    }
    
    .slide-image {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      
      img {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }
    }
  }
  
  @media (max-width: $screen-md-max) {
    .slide {
      height: 400px;
    }
  }
  
  @media (max-width: $screen-sm-max) {
    .slide {
      height: 300px;
    }
  }
  
  @media (max-width: $screen-xs-max) {
    .slide {
      height: 200px;
      
      .slide-content {
        .slide-quote {
          font-size: 22px;
        }
      }
      
      .slide-image {
        &:before {
          display: block;
          content: "";
          font-size: 0;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 3;
          background: rgba(0, 0, 0, .45);
          position: absolute;
        }
        /*opacity: .55;*/
      }
    }
  }
}

#header {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 77px;
  background: $brand-primary;
  transition: all .3s ease-in-out;
  
  &:before {
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    border-top: solid 1px rgba(255, 255, 255, .2);
    font-size: 0;
    content: "";
    display: block;
    z-index: 10;
  }
  
  &:after {
    position: absolute;
    top: -16px;
    left: 0;
    width: 100%;
    height: 16px;
    font-size: 0;
    content: "";
    display: block;
    background: url("../images/nav-shadow.png") repeat-x left bottom;
  }
  
  .brand {
    display: block;
    float: left;
    width: 309px;
    padding-top: 2px;
  }
  
  .nav-primary {
    float: right;
    
    .main-menu {
      float: left;
      display: block;
      margin: 0;
      padding: 0;
      
      > li {
        display: block;
        float: left;
        
        a {
          display: block;
          float: left;
          font-size: 16px;
          font-weight: normal;
          font-family: $font-family-serif;
          line-height: 23px;
          padding: 25px 20px;
          color: #fff;
          text-decoration: none;
          text-transform: uppercase;
          
          > span {
            color: #fff;
            display: inline-block;
            border-bottom: solid 4px transparent;
            transition: all .3s ease-in-out;
          }
          
          &:hover {
            > span {
              color: $brand-warning;
              border-color: $brand-warning;
            }
          }
        }
        
        &.active {
          > a {
            > span {
              color: $brand-warning;
              border-color: $brand-warning;
            }
          }
        }
        
        &.menu-donate {
          a {
/*            background: rgba(255, 255, 255, .15);
            
            > span {
              border-bottom-color: transparent !important;
            }*/

            background: $brand-warning;

            > span {
              color: $brand-primary;
            }
          }
          
          &.active {
            > a {
              background: $brand-warning;
              
              > span {
                color: $brand-primary;
              }
              > span {
                
              }
            }
          }
        }
      }
    }
  }
  
  #menu-toggle {
    float: right;
    font-size: 30px;
    line-height: 1;
    color: #fff;
    margin-top: 14px;
  }
  
  @media (min-width: $screen-sm-min) {
    #menu-toggle {
      display: none;
    }
    
    .nav-primary {
      display: block !important;
    }
  }
  
  @media (max-width: $screen-md-max) {
    .nav-primary {
      .main-menu {
        > li {
          > a {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
  
  @media (max-width: $screen-sm-max) {
    height: 61px;
    
    .brand {
      width: 170px;
      padding-top: 10px;
    }
    
    .nav-primary {
      .main-menu {
        > li {
          > a {
            font-size: 14px;
            line-height: 21px;
            padding: 20px 7px;
          }
          
          & + li {
            margin-left: 2px;
          }
        }
      }
    }
  }
  
  @media (max-width: $screen-xs-max) {
    .brand {
      width: 200px;
      padding-top: 5px;
    }
    
    #menu-toggle {
      display: block;
    }
    
    .nav-primary {
      display: none;
      clear: both;
      background: $brand-primary;
      border-top: solid 1px #fff;
      margin-left: -($grid-gutter-width/2);
      margin-right: -($grid-gutter-width/2);
      float: none;
      
      .main-menu {
        padding: $grid-gutter-width/2;
        float: none;
        
        > li {
          float: none;
          
          > a {
            float: none;
            padding: 10px;
          }
          
          & + li {
            margin: 0;
          }
        }
      }
    }
  }
  
  .fixed-nav & {
    position: fixed;
    top: 0;
  }
  
  .admin-bar.fixed-nav & {
    top: 32px;
  }
}