// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.form-control {
  background-color: #e1e1e1;
	box-shadow: 2px 2px 4px rgba(0,0,0,.28);
	border: solid 1px #fff;
  font-size: 14px;
  font-family: $font-family-sans-serif;
  outline: none;
  line-height: 20px;
  padding: 5px 20px;
  height: auto;
  color: $brand-primary;
  border-radius: 0;
}

textarea.form-control {
  height: 112px;
}

.wpcf7 {
  .form-row {
    margin: 0 0 8px;
  }
  
  .blue-button {
    font-size: 15px;
  }
}